import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import useAuthContext from '../../hooks/useAuthContext';
import { Skeleton } from 'antd';

interface Props {
  component: any;
  exact?: any;
  path: string;
}
const canNavigateToRoute = (role: string) => {
  const allowedRoles = ['super-admin', 'admin', 'user'];
  return allowedRoles.includes(role);
};

const UserProtectedRoute: React.FC<Props> = ({ component: Component, ...rest }) => {
  const { state } = useAuthContext();
  const role = state.user?.role || '.';
  const redirectToUrl = role === 'admin' || role === 'user' ? '/tickets' : '/reports';
  if (state.isLoggedIn === 'loading') {
    return (
      <div>
        <Skeleton active />
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        state.isLoggedIn === 'true' && canNavigateToRoute(role) ? (
          <Component {...props} />
        ) : (
          <Redirect to={redirectToUrl} />
        )
      }
    />
  );
};

export default UserProtectedRoute;
