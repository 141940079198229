import { AuthContextActionsTypes, AuthContextInitialValuesTypes } from '../types/AuthContextTypes';

export const rootReducer = (
  state: AuthContextInitialValuesTypes,
  action: AuthContextActionsTypes,
) => {
  switch (action.type) {
    case 'CHANGE_AUTH_STATE':
      return { ...state, isLoggedIn: action.payload };
    case 'ADD_USER':
      return { ...state, user: action.payload };
    default:
      return state;
  }
};
