import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

// Comment the following code if you want to connect to the Firebase App
// Please try to use the local emulator for development

// if (process.env.NODE_ENV === 'development') {
//   firebase.auth().useEmulator('http://localhost:9099');
//   firebase.firestore().useEmulator('localhost', 8080);
//   firebase.functions().useEmulator('localhost', 5001);
//   firebase.storage().useEmulator('localhost', 9199);
// }

export const firebaseAuthInstance = firebase.auth();
export const firebaseDBInstance = firebase.firestore();
export const firebaseStorageInstance = firebase.storage();
export const firebaseInstance = firebase;

export default firebase;
