import React, { createContext, Dispatch, useEffect, useReducer } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthContextActionsTypes, AuthContextInitialValuesTypes } from '../types/AuthContextTypes';
import { rootReducer } from '../reducers/rootReducer';
import { useLocalStorage } from 'react-use';
import { firebaseAuthInstance } from '../firebase';

interface Props {}

const initialStateValues: AuthContextInitialValuesTypes = {
  isLoggedIn: 'loading',
};

export const AuthContext = createContext<{
  state: AuthContextInitialValuesTypes;
  dispatch: Dispatch<AuthContextActionsTypes>;
}>({
  state: initialStateValues,
  dispatch: () => null,
});

const AuthContextProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, initialStateValues);
  const [selectedKey] = useLocalStorage<string[]>('selected-menu-option');
  const { push } = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    return firebaseAuthInstance.onAuthStateChanged((user) => {
      if (user) {
        dispatch({ type: 'CHANGE_AUTH_STATE', payload: 'true' });
        user.getIdTokenResult().then((idTokenResult) => {
          dispatch({
            type: 'ADD_USER',
            payload: {
              role: idTokenResult.claims.role,
            },
          });
        });
      } else {
        dispatch({ type: 'CHANGE_AUTH_STATE', payload: 'false' });
        if (!pathname.includes('/auth/callback')) {
          push('/login');
        }
      }
    });
  }, [selectedKey, push, pathname]);

  return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
