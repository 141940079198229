import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

const useAuthContext = () => {
  const { state, dispatch } = useContext(AuthContext);

  return {
    state,
    dispatch,
  };
};

export default useAuthContext;
