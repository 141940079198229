import React from 'react';
import ConwasteLogo from '../../assets/conwaste_logo.png';

interface Props {}

const Footer: React.FC<Props> = () => {
  return (
    <div className="text-center mb-5">
      <img src={ConwasteLogo} alt="conwaste logo" className="h-5 mx-auto" />
    </div>
  );
};

export default Footer;
