import React, { useState } from 'react';
import { Layout } from 'antd';
import LeftNavigationMenu from './LeftNavigationMenu';
import HeaderComponent from './Header';
import FooterComponent from './Footer';
import useAuthContext from '../../hooks/useAuthContext';

const { Header, Sider, Content } = Layout;

interface Props {}

const LayoutComponent: React.FC<Props> = ({ children }) => {
  const { state } = useAuthContext();
  const [collapsed, toggleCollapsed] = useState<boolean>(false);
  const onCollapse = () => {
    toggleCollapsed(!collapsed);
  };
  return (
    <Layout className="h-screen">
      <Header className="flex justify-start items-center bg-white shadow-sm z-30 sticky w-full top-0 px-10">
        <HeaderComponent />
      </Header>
      <Layout>
        {state.isLoggedIn === 'true' && (
          <Sider
            breakpoint="sm"
            collapsedWidth="60"
            className="h-full bg-white"
            collapsible
            collapsed={collapsed}
            onCollapse={onCollapse}
          >
            <LeftNavigationMenu />
          </Sider>
        )}
        <Content className="h-full bg-gray-100 p-8 overflow-y-scroll md:p-12 lg:p-16">
          {children}
        </Content>
        {state.isLoggedIn === 'false' && <FooterComponent />}
      </Layout>
    </Layout>
  );
};

export default LayoutComponent;
