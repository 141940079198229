import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Menu } from 'antd';
import {
  BarChartOutlined,
  FormOutlined,
  GlobalOutlined,
  TeamOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import ConwasteLogo from '../../assets/conwaste_logo.png';

// Types
import { MenuInfo } from 'rc-menu/lib/interface';
import { useLocalStorage } from 'react-use';
import useAuthContext from '../../hooks/useAuthContext';

interface Props {}

const LeftNavigationMenu: React.FC<Props> = () => {
  const { state } = useAuthContext();
  const role = state.user?.role || '';
  const initialSelectedKey = role === 'admin' || role === 'user' ? '/tickets' : '/reports';
  const [selectedKeys, setSelectedKeys] = useLocalStorage('selected-menu-option', [
    initialSelectedKey,
  ]);
  let navItemVisibility = {
    tickets: true,
    reports: true,
    'map-view': true,
    content: true,
    users: true,
  };

  switch (role) {
    case 'municipal':
      navItemVisibility = { ...navItemVisibility, tickets: false, content: false, users: false };
      break;
    case 'admin':
      navItemVisibility = { ...navItemVisibility, content: false, users: false };
      break;
    case 'user':
      navItemVisibility = {
        ...navItemVisibility,
        reports: false,
        'map-view': false,
        content: false,
        users: false,
      };
      break;
    default:
      break;
  }

  const { push } = useHistory();

  useEffect(() => {
    if (selectedKeys) {
      push(`${selectedKeys}`);
    }
  }, [selectedKeys]);

  const handleClick = (event: MenuInfo) => {
    const eventAction = (pathname: string) => {
      setSelectedKeys([event.key]);
      push(pathname);
    };
    switch (event.key) {
      case '/tickets':
        eventAction('/tickets');
        return;
      case '/reports':
        eventAction('/reports');
        return;
      case '/map-view':
        eventAction('/map-view');
        return;
      case '/content':
        eventAction('/content');
        return;
      case '/users':
        eventAction('/users');
        return;
      default:
        eventAction('/tickets');
        return;
    }
  };

  return (
    <div className="h-full flex flex-col justify-between py-8">
      <Menu onClick={handleClick} selectedKeys={selectedKeys} mode="inline">
        {navItemVisibility.tickets && (
          <Menu.Item icon={<UnorderedListOutlined />} key="/tickets">
            Tickets
          </Menu.Item>
        )}
        {navItemVisibility.reports && (
          <Menu.Item icon={<BarChartOutlined />} key="/reports">
            Reports
          </Menu.Item>
        )}
        {navItemVisibility['map-view'] && (
          <Menu.Item icon={<GlobalOutlined />} key="/map-view">
            Map View
          </Menu.Item>
        )}
        {navItemVisibility.content && (
          <Menu.Item icon={<FormOutlined />} key="/content">
            Content
          </Menu.Item>
        )}
        {navItemVisibility.users && (
          <Menu.Item icon={<TeamOutlined />} key="/users">
            Users
          </Menu.Item>
        )}
      </Menu>
      <div className="items-center flex flex-col">
        <img src={ConwasteLogo} alt="Conwaste logo" className="w-32" />
      </div>
    </div>
  );
};

export default LeftNavigationMenu;
