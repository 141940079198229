import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Menu, message } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { MenuInfo } from 'rc-menu/lib/interface';
import { firebaseAuthInstance } from '../../firebase';
import useAuthContext from '../../hooks/useAuthContext';
import { capitalCase } from 'change-case';
import ConwasteLogo from '../../assets/carolina_logo.png';

interface Props {}

const Header: React.FC<Props> = () => {
  const { state } = useAuthContext();
  const brandingLogoResourceUrl = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/assets%2FLogo_Carolina?alt=media`;
  const [conWasteLogo, setConWasteLogo] = useState<string>(brandingLogoResourceUrl);

  const onImageError = () => {
    setConWasteLogo(ConwasteLogo);
  };

  function handleMenuClick(e: MenuInfo) {
    if (e.key === 'logout') {
      firebaseAuthInstance.signOut().then(() => {
        message.success('Logged out');
        window.location.reload();
      });
    }
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="logout" icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="flex flex-row justify-between items-center w-full">
      <Link to="/tickets" className="flex justify-center items-center">
        <img
          src={conWasteLogo}
          alt="carolina logo"
          width="125"
          className="header-logo"
          onError={onImageError}
        />
        <h5 className="m-0">Carolina Limpio</h5>
      </Link>
      {state.isLoggedIn === 'true' && (
        <div>
          <Dropdown.Button overlay={menu} placement="bottomLeft" icon={<UserOutlined />}>
            {state.user?.role && capitalCase(state.user?.role)}
          </Dropdown.Button>
        </div>
      )}
    </div>
  );
};

export default Header;
