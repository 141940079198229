import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClientProvider, QueryClient } from 'react-query';

import { Skeleton } from 'antd';
import Layout from './components/GlobalNav/LayoutComponent';
import MunicipalProtectedRoute from './components/common/MunicipalProtectedRoute';
import UserProtectedRoute from './components/common/UserProtectedRoute';
import SuperAdminProtectedRoute from './components/common/SuperAdminProtectedRoute';
import AuthContextProvider from './context/AuthContext';

// Views
const ReportsView = lazy(() => import('./views/ReportsView'));
const ContentView = lazy(() => import('./views/ContentView'));
const MapView = lazy(() => import('./views/MapView'));
const Login = lazy(() => import('./views/Login'));
const Tickets = lazy(() => import('./views/Tickets'));
const Users = lazy(() => import('./views/Users'));
const SingleUser = lazy(() => import('./views/SingleUser'));
const AuthCallback = lazy(() => import('./views/AuthCallback'));

const client = new QueryClient();

interface Props {}

const Routes: React.FC<Props> = () => {
  return (
    <Router>
      <QueryClientProvider client={client}>
        <AuthContextProvider>
          <Layout>
            <ReactQueryDevtools initialIsOpen={false} />
            <Switch>
              <Suspense fallback={<Skeleton active />}>
                <Route exact path="/login" component={Login} />
                <Route exact path="/auth/callback" component={AuthCallback} />
                {/* 'municipal','admin','super-admin' */}
                <MunicipalProtectedRoute exact path="/reports" component={ReportsView} />
                <MunicipalProtectedRoute exact path="/map-view" component={MapView} />
                {/* 'user','admin','super-admin' */}
                <UserProtectedRoute exact path="/tickets" component={Tickets} />
                {/* super-admin only */}
                <SuperAdminProtectedRoute exact path="/content" component={ContentView} />
                <SuperAdminProtectedRoute exact path="/users" component={Users} />
                <SuperAdminProtectedRoute exact path="/user/create" component={SingleUser} />
                <SuperAdminProtectedRoute exact path="/user/update" component={SingleUser} />
              </Suspense>
            </Switch>
          </Layout>
        </AuthContextProvider>
      </QueryClientProvider>
    </Router>
  );
};

export default Routes;
